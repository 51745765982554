﻿export const Paises = {
    BRASIL: 'BRA',
    CHILE: 'CHL',
    COLOMBIA: 'COL',
    COSTA_RICA: 'CRI',
    ECUADOR: 'ECU',
    MEXICO: 'MEX',
};
export const PaisesEnum = {
    BRASIL: 32,
    CHILE: 43,
    COLOMBIA: 47,
    COSTA_RICA: 52,
    ECUADOR: 57,
    MEXICO: 149,
};

export const Subramos = {
    AGRICOLA: 1,
    PECUARIO: 2,
};

export const SubramosEnum = {
    1: "AGRICOLA",
    2: "PECUARIO",
};

export const TipoPersona = {
    MORAL: 'moral',
    FISICA: 'física',
};

export const TipoPersonaEnum = {
    FISICA: 1,
    MORAL: 2,
};

export const EstatusCotizacion = {
    'nueva-solicitud': 'Nueva Solicitud',
    'busqueda-draft': 'Búsqueda de Draft',
    'oferta-comercial': 'Oferta Comercial',
    'carga-documentos': 'Carga Documentos',
    'grado-riesgo': 'Grado Riesgo',
    'alta-predio': 'Alta de Predio',
    'beneficiario-habilitador': 'Beneficiario y Habilitador',
    'finalizada': 'Cotizacion Finalizada',
    'emitida': 'Cotizacion Emitida',
    'rechazada': 'Cotizacion Rechazada',
};

/**
 * Tipos de preguntas de formularios
 */
export const TipoPregunta = {
    Texto: 'text',
    Lista_seleccion: 'omultiple',
    Lista_multiple: 'omultiple2',
    Matriz_seleccion: 'omatriz',
    Matriz_multiple: 'omatriz2',
    Seccion: 'seccion',
    Foto: 'foto',
    Firma: 'firma',
    Titulo: 'title',
    Catalogo: 'cmaster',
};

/**
 * Tipos de ubicación de predio
 */
export const TipoUbicacionPredio = {
    Checkin: 'checkin',
    Checkout: 'checkout',
};

/**
 * Tipos de visitas
 */
export const TipoVisita = {
    AvisoSiniestro: 'Aviso de Siniestro',
    AvisoCosecha: 'Aviso de Cosecha',
    Suscripcion: 'Suscripción',
    SupervicionCredito: 'Supervisión de Crédito',
    Otros: 'Otros',
};

/**
 * Estatus de la orden de trabajo
 */
export const EstatusOrdenTrabajo = {
    Visitada: 'Visitada',
    Asignada: 'Asignada',
    Nuevo: 'Nuevo',
    Pendiente: 'Pendiente',
    Aprobada: 'Aprobada',
    Fondeada: 'Fondeada',
    Creada: 'Creada',
    Rechazada: 'Rechazada',
    Cancelada: 'Cancelada',
    Finalizada: 'Finalizada',
    FinalizadaIncompleta: 'Finalizada-Incompleta',
    EnAprobacionPorCoordinador: 'En Aprobación por Coordinador',
    AprobadaPorCoordinador: 'Aprobada por Coordinador',
    EnAprobacionSubdireccionViaticos: 'En Aprobación Subdirección Viáticos',
    AprobadaPorSubdireccionViaticos: 'Aprobada por Subdireccion Viaticos',
};

/**
 * Tipos de solicitud de visita
 */
export const TipoSolicitud = {
    Global: 'Global',
    Individual: 'Individual'
};

/**
 * Tipos de permisos
 */
export const Permisos = {
    EditarFechasOrden: 'editar_fechas_orden',
    DetalleOrden: 'detalle_orden',
    ComprobarViaticos: 'comprobar_viaticos',
    AprobarViaticos: 'aprobar_viaticos',
    DevolucionGasto: 'devolucion_gasto',
    CancelarOrden: 'cancelar_orden',
    AgregarVisitaOrdenesTrabajo: 'agregar_visita_ordenes_trabajo',
    GuardarOrdenesTrabajo: 'guardar_ordenes_trabajo',
    GenerarRutaOrdenesTrabajo: 'generar_ruta_ordenes_trabajo',
    AgregarGastoOrdenesTrabajo: 'agregar_gasto_ordenes_trabajo',
    CoordenadasVisita: 'coordenadas_visita',
    EliminarVisita: 'eliminar_visita',
    EditarEmpresa: 'empresas_edit',
    ActivarEmpresa: 'empresas_activarempresa',
    DesactivarEmpresa: 'empresas_desactivarempresa',
}
/**
 * Esquemas de aseguramientos subramo Pecuario
 */
export const TipoSeguroEnum = {
    PAGP: "PAGP",
    IAR: "IAR",
    DD: "DD",
    PLANTA: "PLANTA",
    CATP: "CATP",
    CASA: "CASA",
    SEIN: "SEIN",
    AINT: "AINT",
    INV: "INV",
    INVP: "INVP",
    HUR: "HUR",
    PE: "PE",
    PEC: "PEC",
    ACUI: "ACUICOLA",
    RADICACION: "RADICACION",
    ADAPTACION: "ADAPTACION",
    APICOLA: "APICOLA",
    AVICOLA: "AVICOLA",
    ESTANCIA_TEMPORAL: "ESTANCIA_TEMPORAL",
    ALTA_MORTALIDAD: "ALTA_MORTALIDAD",
    TRANSPORTE: "TRANSPORTE",
    CATASTROFICO_DANOS_PASTIZALES: "CATASTROFICO_DAÑOS_PASTIZALES"
};
export const GrupoSeguroPecuarioEnum = {
    UnidadesAsegurar:["ACUICOLA","APICOLA","CATASTROFICO_DAÑOS_PASTIZALES"],
    MachosHembrasConSinRegistro: ["RADICACION","ADAPTACION","ALTA_MORTALIDAD","TRANSPORTE", "ESTANCIA_TEMPORAL" ],
    MachosHembrasSinRegistro: ["AVICOLA"]
};